import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { age } from '../../../shared/utils/age';
import { Season } from 'libs/interfaces/src';

export class CustomValidators {
    static dates(activeSeasons: Season[]): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const startDate = formGroup.get('startDate')?.value;
            const endDate = formGroup.get('endDate')?.value;
            const currentDate = new Date();

            if (!startDate || !endDate) {
                return { required: true };
            }

            // Check if dates are in the future
            if (startDate > currentDate || endDate > currentDate) {
                return { futureDate: true };
            }

            // Check if the date range is at least 7 days
            const timeDifference = new Date(endDate).getTime() - new Date(startDate).getTime();
            const dayDifference = timeDifference / (1000 * 3600 * 24);
            if (dayDifference < 6) {
                return { minDays: true };
            }

            // Check if dates fall within active seasons
            const isWithinSeason = activeSeasons.some(season => {
                if (!season.startDate || !season.endDate) {
                    return false;
                }

                return startDate >= new Date(season.startDate) && endDate <= new Date(season.endDate);
            });

            if (!isWithinSeason) {
                return { outOfSeason: true };
            }

            //Check if the end date is in between today and 30 days back
            const thirtyDaysAgoDate = new Date();
            thirtyDaysAgoDate.setUTCDate(currentDate.getUTCDate() - 30);
            if (endDate > currentDate || endDate < thirtyDaysAgoDate) {
                return { endInThirtyDays: true };
            }

            return null;
        };
    }

    static spouse(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const hasTraveledWithSpouse = formGroup.get('hasTraveledWithSpouse')?.value;
            const isSpouseSiemensEmployee = formGroup.get('isSpouseSiemensEmployee')?.value;

            if (hasTraveledWithSpouse === null) {
                return { required: true };
            }

            if (hasTraveledWithSpouse) {
                CustomValidators.setValidators(
                    formGroup,
                    ['spouseName', 'hasSpouseSevereDisability', 'isSpouseSiemensEmployee'],
                    [Validators.required]
                );
                // The form field is required only if the user is traveled with spose
                if (isSpouseSiemensEmployee) {
                    CustomValidators.setValidators(
                        formGroup,
                        ['spouseGid'],
                        [Validators.required, CustomValidators.gid()]
                    );
                } else {
                    CustomValidators.clearValidators(formGroup, ['spouseGid']);
                    CustomValidators.setValidators(formGroup, ['spouseGid'], [CustomValidators.gid()]);
                }
            } else {
                CustomValidators.clearValidators(formGroup, [
                    'spouseName',
                    'hasSpouseSevereDisability',
                    'isSpouseSiemensEmployee',
                    'spouseGid'
                ]);
            }

            return null;
        };
    }

    static children(): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const hasTraveledWithChild = formGroup.get('hasTraveledWithChild')?.value;

            if (hasTraveledWithChild === null) {
                return { required: true };
            }

            if (hasTraveledWithChild) {
                CustomValidators.setValidators(formGroup, ['children'], [Validators.required, Validators.minLength(1)]);
            } else {
                CustomValidators.clearValidators(formGroup, ['children']);
            }

            return null;
        };
    }

    static gid(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value || typeof value !== 'string') {
                return null;
            }

            return value.toUpperCase().startsWith('Z') && value.length === 8 ? null : { gid: true };
        };
    }

    static age(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }

            return age(new Date(value)) < max ? null : { age: true };
        };
    }

    static pastDate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }

            return new Date(value) < new Date() ? null : { pastDate: true };
        };
    }

    static minArrayLength(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }

            return value.length >= min ? null : { minArrayLength: true };
        };
    }

    private static setValidators(formGroup: AbstractControl, controlNames: string[], validators: ValidatorFn[]) {
        controlNames.forEach(name => {
            const control = formGroup.get(name);
            if (control) {
                control.setValidators(validators);
                control.updateValueAndValidity({ emitEvent: false, onlySelf: true });
            }
        });
    }

    private static clearValidators(formGroup: AbstractControl, controlNames: string[]) {
        controlNames.forEach(name => {
            const control = formGroup.get(name);
            if (control) {
                control.clearValidators();
                control.updateValueAndValidity({ emitEvent: false, onlySelf: true });
            }
        });
    }
}
